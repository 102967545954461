.amplify-tabs {
  display: none;
}

.amplify-input {
  @extend .form-control;
}

.amplify-button[type="submit"] {
  @extend .btn;
  @extend .btn-success;
  @extend .btn-lg;
}

.amplify-alert {
  @extend .alert;
  margin-bottom: 0;
}

.amplify-alert[data-variation="error"] {
  @extend .alert-danger;
}

[data-amplify-authenticator] {
  width: 100vw;
  min-height: 100vh;
}

[data-amplify-authenticator] [data-amplify-container] {
  @extend .card;
  overflow: hidden;
}
