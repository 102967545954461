.ged-resource {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
  overflow: hidden;
  &__name {
    white-space: nowrap;
    margin-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--folder {
    a {
      text-decoration: none;
      color: #212529;
    }
  }
}
