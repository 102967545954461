$base-margin: 16px;

#model-viewer-container {
  width: 100%;
  height: 100%;
  position: relative;

  .model-viewer-info-container {
    position: absolute;
    height: 100%;
    padding: $base-margin;
    z-index: 2;
    opacity: 0.85;
  }
}
