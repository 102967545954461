@use "sass:math";

// Overrides

$primary: #18833d;

// Dependencies

@import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
@import "photo-sphere-viewer/dist/plugins/markers.css";
@import "../shards-dashboard/styles/scss/shards-dashboards.scss";
@import "../amplify";

// Constants

$base-margin: 14px;

// Components

@import "../components/model-info/ModelInfo";
@import "../components/powerbi/ReportView";

.navbar-brand {
  padding: 0;
}

// Component/Breadcrumb

.breadcrumb-item {
  &.active {
    //color: $primary
  }

  a {
    color: #6c757d;
  }
}

// Component/SideBar

.sidebar-nav-item {
  a {
    display: flex;
  }

  a.active &__expand {
    transform: rotate(90deg);
  }

  &__children {
    max-height: 0px;
    transition: max-height 0.4s;
    background-color: #f5f6f8;

    .nav-link {
      display: flex;
      //justify-content: flex-end;
    }
  }

  a.active + &__children {
    max-height: 400px;
  }

  &__expand {
    transition: transform 0.4s;
    margin-left: auto !important;
  }
}

// Component/BuildingResources

.building-resources {
  .building-resource {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-secondary;
    font-size: 12px;
    display: flex;

    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 14px;
    text-decoration: none !important;
    border-radius: 8px;

    &:last-child {
      margin-right: 0px;
    }

    i {
      font-size: 22px;
      display: flex;
      height: 28px;
    }
  }
}

// Component/Viewer3D

.viewer-3D-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .viewer-3D-pane {
    height: 100%;
    width: 30%;
    max-width: 400px;
    overflow: scroll;
    padding: 0 1rem;

    .section-header {
      background-color: #e9ecef;
      padding: 15px;
      border-radius: 6px;

      h6 {
        //font-weight: 500
      }

      margin-bottom: 15px;
    }

    .table {
      tr {
        &:first-child {
          td {
            border-top: none !important;
          }
        }
      }
    }

    .equipment {
      height: 250px;

      &__placeholder {
        border: dashed 3px #e8e8e8;
        text-align: center;
        padding: 15px;
        height: 100%;
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .viewer {
    position: relative;
    flex: 1;
    height: 100%;
  }
}

// View/Building

.building-row {
  & > * {
    margin-bottom: 30px;
  }

  .widget {
    height: "100%";
    overflow: "hidden";
    pointer-events: none;
  }
}

.building-map {
  @extend .card;
  overflow: hidden;
  width: 100%;
  height: 200px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .map {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      flex-shrink: 0;
    }
  }
}

// View/Document

.document {
  display: flex;
  flex-direction: column;
  height: 80vh;

  &__file-list {
    overflow: scroll;
    flex: 1;
    background-color: white;
  }

  &__search-bar {
    padding: 15px;
    background-color: white;
  }

  &__total {
    margin-top: auto;
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }

  th {
    @extend .text-muted;
    font-weight: normal;
    border-bottom-width: 1px !important;
  }

  td {
    vertical-align: middle;

    &.last-modified {
      @extend .text-muted;
      white-space: nowrap;
    }

    &.size {
      @extend .text-muted;
      white-space: nowrap;
    }

    &.download {
      padding: unset;

      button {
        font-size: 20px;
        color: #007bff;
      }
    }
  }

  tr {
    &.folder {
      cursor: pointer;
    }

    &:last-child {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .user-details {
    a {
      color: unset;
    }

    .user-avatar {
      height: 20px;
    }
  }
}

.full-screen-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 $base-margin;

  > * {
    border-radius: 0.25rem;
    overflow: hidden;
  }
}

// View/Photospheres

.photosphere-container {
  @extend .full-screen-container;

  > * {
    border-radius: 0.25rem;
    overflow: hidden;
  }

  .admin-menu {
    position: absolute;
    right: $base-margin * 2;
    bottom: 40px + $base-margin;
    z-index: 21;

    button {
      margin-right: 10px;
    }
  }
}

.photosphere-container .model-viewer-info-container {
  position: absolute;
  height: 100%;
  padding: $base-margin;
  padding-bottom: 40px + $base-margin;
  z-index: 100;
}

.photosphere-marker-detail {
  position: absolute;
  left: $base-margin * 2;
  top: $base-margin;
  z-index: 21;

  .mini-icon-container {
    width: 10px !important;
  }

  .bullet-text {
    width: 300px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .span-anchor {
    color: #18833d;
    background-color: transparent;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .bullet-text:hover {
    text-overflow: "";
    overflow: auto;
    white-space: normal;
  }

  .span-anchor:hover {
    cursor: pointer;
    color: white;
    background-color: #18833d;
  }

  &__delete {
    cursor: pointer;
    color: $danger;
  }

  &__close {
    cursor: pointer;
    font-size: 18px;
    margin-left: 15px;
  }

  .card {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .card-header,
  .card-body,
  .card-body .list-group-item {
    background-color: unset;
  }
}

.photosphere-map {
  position: absolute;
  left: $base-margin * 2;
  z-index: 21;
  top: $base-margin;
}

.photosphere-map-close {
  position: absolute;
  font-size: 24px;
  top: $base-margin;
  right: $base-margin;
  cursor: pointer;
}

.photosphere-pane.small {
  @extend .card;
  position: absolute;
  right: $base-margin * 1.2;
  top: math.div($base-margin, 5);
  z-index: 21;
  padding: math.div($base-margin, 2);
  display: flex;
  overflow: visible;
}

// Amplify Component custom

.amplify-tabs {
  display: none;
}

.amplify-alert {
  @extend .alert;
}

.amplify-button {
  @extend .btn;
}

.amplify-button[type="submit"] {
  @extend .btn;
  border-radius: 5px !important;
  padding: 1rem;
  background-color: #18833d !important;
  font-weight: 400;
  border: 1px solid transparent;
  font-size: 0.75rem;
  line-height: 1.125;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  text-transform: uppercase;
}

.amplify-button:focus {
  outline: none;
}

[data-amplify-authenticator] [data-amplify-container] {
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  background-color: white;

  &::before {
    content: "";
    background: url("./setec-logo.jpg");
    width: 216px;
    height: 100px;
    display: block;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    margin: 25px auto auto auto;
  }
}

h3.amplify-heading {
  @extend .h5;
}

.amplify-textfield input {
  @extend .form-control;
}

:root {
  --amplify-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  --amplify-primary-color: #18833d;
  --amplify-secondary-color: #5a6169;
  --amplify-primary-tint: #156832;
}

.edit-marker-menu {
  float: right;
  bottom: 40px;
  margin: 3px;

  button {
    margin-right: 10px;
  }
}

.map-container {
  overflow: visible;
}

.map-container.huge {
  height: 100%;
  width: 100%;
}

.huge-map-obj-container {
  position: absolute;
  left: 15px;
  bottom: 15px;
  top: 0;
  right: 30%;
  border-radius: 0.25rem;
  overflow: hidden;

  @media (max-width: 990px) {
    display: none;
  }
}

.photospheres-container {
  position: absolute;
  left: 70%;
  bottom: 0;
  top: 0;
  right: 0;
  overflow: scroll;

  @media (max-width: 990px) {
    left: 10px;
    right: 0;
  }
}

.map-edit-floor-dropdown {
  position: absolute;
  left: 5px;
  bottom: 5px;
  margin: 5px;
  z-index: 1000;

  button {
    width: 80px;
  }
}

.map-edit-button {
  position: absolute;
  left: 90px;
  bottom: 5px;
  margin: 5px;
  z-index: 1000;
  width: 80px;
}

.map-edit-save-button {
  position: absolute;
  left: 90px;
  bottom: 5px;
  margin: 5px;
  z-index: 1000;
  width: 100px;
}

.map-edit-cancel-button {
  position: absolute;
  left: 5px;
  bottom: 5px;
  margin: 5px;
  z-index: 1000;
  width: 80px;

  @media (max-width: 800px) {
    width: 60px;
  }
}

.small .leaflet-container {
  width: 500px;
  height: 200px;

  @media (max-width: 800px) {
    width: 400px;
    height: 150px;
  }
}

.huge .leaflet-container {
  height: 100%;
  width: 100%;
}

.huge .marker-form {
  width: 100%;
  padding: 5px;
  background: white;
  z-index: 2000;
  position: absolute;
  bottom: 0;
}

.location-pin img {
  width: 34px;
  height: 30px;
  margin: -3px 0 0 -3px;
  z-index: 10;
  border-radius: 50%;
}

div button.breadcrumb-dropdown.btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: black;
  box-shadow: none !important;
  padding: 2px;
  margin: 0 auto;
}

div button.breadcrumb-dropdown.btn-primary.dropdown-toggle:active {
  background-color: transparent;
  border-color: transparent;
  color: black;
  box-shadow: none !important;
}

div.show button.breadcrumb-dropdown.btn-primary.dropdown-toggle {
  box-shadow: none !important;
}
