.model-info {
  width: 31em;
  height: 100%;

  .card-subtitle {
    font-size: 0.8rem;
  }

  .card-body {
    padding: 1.25rem;
    overflow: auto;
  }

  .close-button {
    position: absolute;
    top: $base-margin;
    right: $base-margin;
  }

  .sections {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .section {
      margin-bottom: 10px;
      .section-title {
        //font-size: 1.1rem;
        margin: 0 -0.5rem 10px -0.5rem;
        background-color: #c9c9c94a;
        border-radius: 2px;
        padding: 2px 12px;
      }
    }
  }

  .ged-resources {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }

  .interventions {
    display: flex;
    flex-direction: column;
    .intervention {
      flex: 1;
      padding: 4px;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      cursor: pointer;

      > div {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        &.expand {
          transition: transform 0.4s;
          &.collapsed {
            transform: rotate(-90deg);
          }
        }

        &.date {
          padding-right: 8px;
        }

        &.label {
          flex-basis: 30%;
          padding-right: 4px;
        }
        &.code {
          flex-basis: 20%;
          margin-left: auto;
          text-align: center;
        }

        &.status {
          flex-basis: 20%;
          text-align: center;
        }
      }
    }
    .intervention-details {
      padding-top: 2px;
      padding-left: 15px;
    }
  }

  .text-container h3 {
    margin-top: 16px;
    width: fit-content;
  }

  .text-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .text-content img {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .text-content p {
    margin-right: 5px;
  }

  .markdown-text {
    overflow-wrap: break-word;
    width: 100%;
  }
}
